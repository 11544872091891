import React, { useState } from 'react';
import tw from 'tailwind.macro';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import SEO from '../components/seo';
import Footer from '../components/Footer';
import ContactSection from '../components/ContactSection';
import Nav from '../components/Nav';
import linkedIn from '../images/linkedin.png';
import { Link } from 'gatsby';

const Wrapper = tw.div`
  flex items-center flex-col 
`;

const TeamSection = tw.div`
  w-full bg-themeGreen-200 font-medium justify-center text-themeGreen-500 pt-20 px-6
  sm:pt-12
`;

const HeroTextMobile = styled('h1')`
  ${tw`hidden sm:block sm:text-3xl text-themeGreen-700`};

  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 123.19%;
`;

const TabsMobile = tw.div`
  w-full hidden my-6
  sm:flex 
`;

const TabOption = styled('div')`
  margin-right: 2rem;
  cursor: pointer;
  font-weight: bold;
  ${props => (!!props.selected ? tw`text-themeGreen-500` : tw`text-black`)};
`;

const TeamContainer = tw.div`
  max-w-6xl m-auto
`;

const TeamHeader = tw.h1`
  text-4xl text-themeGreen-800 font-bold mb-10
  sm:hidden
`;

const TeamSectionGrid = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  column-gap: 2rem;
  row-gap: 3rem;
  ${props => (!!props.show ? tw`sm:block` : tw`sm:hidden`)};
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const TeamSectionTile = styled('div')`
  ${tw`text-themeGreen-800`};

  grid-column: span 1 / span 1 !important;
`;

const TeamSectionTileInner = styled('div')`
  ${tw`my-4 text-themeGreen-800 py-2 relative
    sm:py-0 sm:pl-0 sm:pr-2 sm:pb-16
  `};
`;

const TeamSectionTileName = styled('h2')`
  ${tw`text-sm font-bold pb-4 `};
`;

const TeamSectionTileBody = styled('p')`
  ${tw`font-normal text-sm sm:text-xs leading-loose`};
  color: #586461;
  font-family: Open Sans;
`;

const LinkedInLink = styled('a')`
  ${tw`absolute right-0 
    sm:left-0 sm:ml-0
`};
  @media (max-width: 800px) {
    bottom: 6%;
  }
  height: 32px;
  width: 32px;
`;

const LinkedInImg = styled('img')`
  width: 100%;
`;

const ContributeButton = tw.button`
  bg-themeGreen-500 border border-themeGreen-500 text-xs px-5 py-3 leading-none text-white
`;

function Team() {
  const leadership_Opt = 1;
  const team_Opt = 2;

  const [optionSelected, setOptionSelected] = useState(1);

  return (
    <Wrapper>
      <SEO title="Home" />
      <Nav />
      <TeamSection>
        <HeroTextMobile>Our Team</HeroTextMobile>
        <TabsMobile>
          <TabOption selected={optionSelected === leadership_Opt ? true : false} onClick={() => setOptionSelected(1)}>
            Leadership
          </TabOption>
          <TabOption selected={optionSelected === team_Opt ? true : false} onClick={() => setOptionSelected(2)}>
            Team
          </TabOption>
        </TabsMobile>
        <TeamContainer>
          <TeamHeader>Leadership</TeamHeader>
          <TeamSectionGrid show={optionSelected === leadership_Opt ? true : false}>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/andytian/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Andy Tian</TeamSectionTileName>
                <TeamSectionTileBody>
                  Andy Tian is CEO of Asia Innovations, a global mobile app company based in Beijing. He was previously
                  at BCG, Google, and co-founded a Beijing company with Robin Chan which was acquired by Zynga to become
                  Zynga China.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/robinchan/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Robin Chan</TeamSectionTileName>
                <TeamSectionTileBody>
                  Robin Chan is the founder and General Partner of Goat Capital based in San Francisco. Previously he
                  introduced Didi to Uber as an early advisor, started the China supply chain for Bird Rides, and was an
                  angel investor in Xiaomi. He co-founded a Beijing company with Andy Tian which was acquired by Zynga
                  to become Zynga China.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/calilyliu/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Lily Liu</TeamSectionTileName>
                <TeamSectionTileBody>
                  Lily Liu is an investor and advisor, with a background as a CFO and operating executive in the U.S.
                  and China. Most recently, Lily co-founded Earn.com which was acquired by Coinbase. Previously, Lily
                  was the CFO of CHC, where she built a 500-bed private hospital in Cixi, China. Lily also worked at KKR
                  in New York as well as McKinsey in New York and Beijing.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/yanjing-claire-du-75409812/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Claire Du</TeamSectionTileName>
                <TeamSectionTileBody>
                  Claire Du is an impact investor in the education field, advisor to leading institutions such as Aiyou
                  Foundation, the largest private foundation in China, and Owl Ventures.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/bwong/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Brian A. Wong</TeamSectionTileName>
                <TeamSectionTileBody>
                  Brian Wong is Vice President of Global Initiatives at Alibaba Group and joined as the 52nd employee.
                  Brian is also Founder and Chairman of RADII Media, and served as Special Assistant to San Francisco
                  Mayor Willie L. Brown. He was selected as a World Economic Forum Young Global Young Leader (YGL) and
                  is a member of the Aspen Institute’s China Fellowship Program.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/anandpkulkarni/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Anand Kulkarni</TeamSectionTileName>
                <TeamSectionTileBody>
                  Anand Kulkarni is a serial entrepreneur and computer scientist, currently running Crowdbotics, a
                  startup applying machine learning to software development. Anand was previously co-founder and Chief
                  Scientist of LeadGenius, a Y Combinator, Sierra Ventures, and Lumia Capital-backed startup using human
                  computation and deep learning to automate account-based marketing (ABM).
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://8vc.com/team/drew-oetting/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Drew Oetting</TeamSectionTileName>
                <TeamSectionTileBody>
                  Drew Oetting is a co-founder of Operation Masks. He is also a co-founder and partner at 8VC, a Silicon
                  Valley investment firm that has supported dozens of leading companies including Wish.com, Guardant
                  Health, Flexport, Oscar, Oculus, Illumio, and Blend. Drew is also a founder and director of Affinity
                  Technologies.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://8vc.com/team/jake-medwell/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Jake Medwell</TeamSectionTileName>
                <TeamSectionTileBody>
                  Jake Medwell is a co-founder of Operation Masks. He is also a cofounder and partner at 8VC. He
                  previously founded Humin (Acq. Tinder/IAC), The Kairos Society, and Solé Bicycle Co. Jake serves as an
                  advisor and board member to numerous companies.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://8vc.com/team/joe-lonsdale/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Joe Lonsdale</TeamSectionTileName>
                <TeamSectionTileBody>
                  Joe Lonsdale is a co-founder and partner at 8VC. He was an early institutional investor in many
                  notable companies including Wish, Oculus, Oscar, Illumio, Blend, RelateIQ, Joby Aviation, Guardant
                  Health, and Synthego, and in 2016 and 2017 was the youngest member of the Forbes 100 Midas List. Joe
                  also co-founded Palantir, Addepar, and OpenGov.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="http://www.yaoweicapital.com/Home/About" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Erik Zhang</TeamSectionTileName>
                <TeamSectionTileBody>
                  Erik Zhang is an advisor to Operation Masks. He is a founding managing partner of Yao Capital, a
                  global private equity investor in sports and entertainment. Along with Yao Ming, Erik helped in
                  creating Yao Foundation to support sports and physical education in hundreds of Hope Project
                  Elementary Schools. Erik is also a founding board director of WWF-One Planet Foundation, a leading
                  conservation organization in China.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
          </TeamSectionGrid>
          <TeamHeader>Team</TeamHeader>
          <TeamSectionGrid show={optionSelected === team_Opt ? true : false}>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/lukebugbee/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Luke Bugbee</TeamSectionTileName>
                <TeamSectionTileBody>
                  Luke Bugbee is a designer at 8VC. He was an early designer at Quaestor, Esper, and Epirus, and is a
                  consulting designer for a number of other companies within the 8VC portfolio. Previously he was a
                  designer at GovTech companies Camino, and OpenGov. Luke is involved in a number of non-profits
                  including Basil Technologies, and The Friends of Faith.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/colton-pierson-00aab248/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Colton Pierson</TeamSectionTileName>
                <TeamSectionTileBody>
                  Colton Pierson was previously a YC alum and CTO at assembly.com, where he built process and quality
                  control systems for manufacturing and logistics. Recently at Figma and now working on improving the
                  throughput of coronavirus testing at Curative.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/diptidesai/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Dipti Desai</TeamSectionTileName>
                <TeamSectionTileBody>
                  Dipti Desai is a technologist and angel investor with a focus on data, e-commerce, and online
                  marketing. Previously she held roles in product management & product strategy at Uber, worked on
                  data-focused products at SaaS startups in adtech and cleantech, and started her career as a hardware
                  engineer.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/jorgemiguelmargenta/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Jorge Margenta</TeamSectionTileName>
                <TeamSectionTileBody>
                  Jorge Margenta is a technical product manager born in Spain, with background in fintech startups,
                  videogames and sofware factories. He is currently adding muscle to the fight against Covid, while
                  expecting to be back soon riding waves in Central America.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/gerrinepan/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Gerrine Pan</TeamSectionTileName>
                <TeamSectionTileBody>
                  Gerrine Pan is an entrepreneur in the hospitality technology space. She co-founded Relevant Mobile and
                  led enterprise sales there, and served as CBO at a marketing technology company (Bridg). Prior to that
                  she was at Goldman Sachs. Gerrine graduated from Harvard Business School. She is currently a Fellow at
                  the Clean Energy Leadership Institute.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/elizabethchiang/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Liz Chiang</TeamSectionTileName>
                <TeamSectionTileBody>
                  Liz Chiang is an entrepreneur, early-stage advisor, investor and consultant in SaaS go-to-market
                  strategy, sales operations, customer success and product development. She’s spending her quarantine in
                  Brooklyn, NY doing a lot of crossword puzzles and snuggling her rescue dog.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/jainvikrant/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Vikrant Jain</TeamSectionTileName>
                <TeamSectionTileBody>
                  Vikrant Jain is a Program Manager in Microsoft’s Customer Experience Engineering division. He loves
                  building programs and products that enrich lives through simple, impactful design. Vikrant enjoys
                  bringing this mindset to Operation Masks and his personal community-focused projects
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/austinhallock/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Austin Hallock</TeamSectionTileName>
                <TeamSectionTileBody>
                  Austin Hallock is a product builder focused on helping nonprofits. His most recent focus was FreeRoam,
                  a nonprofit helping connect campers with nature in a respectful, sustainable way.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/duncan-graham/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Duncan Graham</TeamSectionTileName>
                <TeamSectionTileBody>
                  Duncan Graham is an independent product and brand designer. He's worked extensively with nonprofits
                  and startups to create beautiful and intuitive experiences that pragmatically solve business goals.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/hansekim/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Hans Kim</TeamSectionTileName>
                <TeamSectionTileBody>
                  Hans Kim serves as general counsel for Operation Masks. He was previously Managing Partner of Atrium
                  LLP, a law firm focused on serving startups. Hans joined Operation Masks after hearing first hand of
                  the PPE shortages from his brothers who are doctors at Cedars Sinai in Los Angeles and Henry Ford
                  Hospital in Detroit.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner>
                <LinkedInLink href="https://www.linkedin.com/in/hammad-shaukat/" target="_blank">
                  <LinkedInImg src={linkedIn} />
                </LinkedInLink>
                <TeamSectionTileName>Hammad Shaukat</TeamSectionTileName>
                <TeamSectionTileBody>
                  Hammad is an experienced full stack Django engineer from Pakistan with background in developing
                  fintech and AI-driven solutions. He is excited to apply his passion for building flawless web
                  applications at Operation Masks.
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
            <TeamSectionTile>
              <TeamSectionTileInner
                css={css`
                  border: none;
                  margin-top: 6px;
                `}
              >
                <TeamSectionTileName>Contribute your talents to the fight against COVID-19</TeamSectionTileName>
                <TeamSectionTileBody>
                  <Link to="/volunteer/">
                    <ContributeButton
                      css={css`
                        margin-top: 47px;
                      `}
                    >
                      Volunteer with us!
                    </ContributeButton>
                  </Link>
                </TeamSectionTileBody>
              </TeamSectionTileInner>
            </TeamSectionTile>
          </TeamSectionGrid>
        </TeamContainer>
      </TeamSection>
      <ContactSection />
      <Footer />
    </Wrapper>
  );
}

export default Team;
